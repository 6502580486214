<header>
  <div class="main">
    <div class="container inner">
      <img
        height="50"
        src="../../../../assets/logos/logo.svg"
        alt="Logo"
      />
      <app-project-name-box
        class="project-name"
        [projectName]="title"
      ></app-project-name-box>
    </div>
  </div>
  <div class="container">
    <div class="header-secondary" [ngClass]="viewpoint ? 'show' : ''">
      <span *ngIf="viewpoint" class="item numberplate">
        <span [innerHTML]="viewpoint.roadsign.title"></span>
        <span class="position" [innerHTML]="viewpoint.roadsign.position"></span>
      </span>
    </div>
  </div>
</header>
<app-back-button *ngIf="viewpoint" [pageName]=" "></app-back-button>