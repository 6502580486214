<div style="width: 100vw; height: 100vh;">
  <as-split (dragEnd)="onDragEnd( $event)" #splitEl>
    <div #tiles id="tiles"></div>
    <as-split-area [size]="config.leftSize" [minSize]="48" style="position: relative">
      <div class="container" *ngIf="!standalone">
        <div class="header-secondary" [ngClass]="viewpoint.main ? 'has-back-btn' : ''">
          <div class="tab" [routerLink]="['/viewpoint']">
            <h3>{{title}}</h3>
          </div>
          <span class="title" *ngIf="viewpoint.main">
            <h2 [innerHTML]="heading"></h2>
          </span>
          <span class="item numberplate" *ngIf="!viewpoint.main">
            <span [innerHTML]="viewpoint.roadsign.title"></span>
            <span class="position" [innerHTML]="viewpoint.roadsign.position"></span>
          </span>
        </div>
      </div>
      <app-back-button [pageName]="home" *ngIf="!viewpoint.main">
      </app-back-button>

      <app-horizontal-toolbar [viewpoint]="viewpoint" [viewpoints]="viewpoints"
        [title]="viewpoint.main ? 'More Viewpoints' : 'Viewpoints'" [toggleBtnTop]="82"></app-horizontal-toolbar>
      <app-switch *ngIf="viewpoint.mode['existing']" [viewpoint]="viewpoint"
        [checked]="state.global.mode === 'proposed'" (check)="toggleScene($event)"
        (checkLayer)="toggleLayerScene($event)"></app-switch>

      <app-compass [viewpoint]="viewpoint" [viewer]="viewer" #compass></app-compass>
      <app-zoom-indicator [viewer]="viewer" [ngClass]="{'embedded':!standalone}"></app-zoom-indicator>
      <app-hotspot *ngFor="let sceneHotspot of sceneHotspots" [scene]="sceneHotspot.scene" [data]="sceneHotspot.hotspot"
        [sceneId]="sceneHotspot.sceneId"></app-hotspot>

      <app-walkthrough *ngIf="!viewpoint.main" [data]="walkthrough.externalViewpoints"></app-walkthrough>
      <app-walkthrough *ngIf="viewpoint.main" [data]="walkthrough.home"></app-walkthrough>


    </as-split-area>
    <as-split-area [size]="config.rightSize" style="z-index: 2">
      <app-minimap [viewer]="viewer" [viewpoint]="viewpoint" [viewpoints]="viewpoints" [zoom]="16">
      </app-minimap>
    </as-split-area>
  </as-split>
</div>