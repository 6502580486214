import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import { Subscription } from 'rxjs';
import { Viewpoint } from '@models/viewpoint.model';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy, OnInit {
  viewpoint: Viewpoint;
  changeViewpointSub: Subscription;
  title: string;


  constructor(private viewpointsService: ViewpointsService, private appService: AppService) {
    this.title = this.appService.heading;
  }

  ngOnInit() {
    this.changeViewpointSub = this.viewpointsService.onChangeViewpoint.subscribe((viewpoint) => {
      this.viewpoint = viewpoint;
    })
  }

  ngOnDestroy(): void {
    this.changeViewpointSub.unsubscribe();
  }
}
