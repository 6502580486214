import { Component, Input, OnInit } from '@angular/core';
import Marzipano from 'marzipano';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-zoom-indicator',
  templateUrl: './zoom-indicator.component.html',
  styleUrls: ['./zoom-indicator.component.scss'],
})
export class ZoomIndicatorComponent implements OnInit {
  private _viewer: Marzipano.Viewer;
  currentZoomLevel: number;
  displayZoomLevel: string;
  maxZoomOutLevel: number;
  maxZoomInLevel: number;
  standalone: boolean;

  constructor(private appService: AppService,  
    private route: ActivatedRoute) { }
  @Input() set viewer(value: Marzipano.Viewer) {
    if (value._domElement) {
      this._viewer = value;
      this.viewer.view().setFov(this.currentZoomLevel);
      this.transformDisplayZoomLevel()
      let that = this;
      this.viewer.addEventListener('viewChange', function () {
        that.currentZoomLevel = that.viewer.view().fov();
        that.transformDisplayZoomLevel();
      });
    }
  }

  get viewer(): Marzipano.Viewer {
    return this._viewer;
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
    let viewpointSlug = params['name'];
    if(viewpointSlug === "viewpoint-05-nw-reba-circle") {
      this.maxZoomInLevel = 0.6
      this.maxZoomOutLevel = 1.3
      this.currentZoomLevel = 0.9
   } else if(viewpointSlug === "viewpoint-07-florida-turnpike") { 
      this.maxZoomInLevel = 0.6
      this.maxZoomOutLevel = 0.9
      this.currentZoomLevel = 1.0
   } else {
       this.maxZoomInLevel = 0.7
       this.maxZoomOutLevel = 1.0
       this.currentZoomLevel = 1.0
   }
    this.standalone = this.appService.isStandalone;
  })}

  ngOnDestroy(): void {
    this.viewer.removeEventListener('viewChange');
  }

  transformDisplayZoomLevel() {
    this.displayZoomLevel = this.currentZoomLevel.toFixed(1);
  }

  zoomOut() {
    if (this.currentZoomLevel < this.maxZoomOutLevel) {
      this.currentZoomLevel += 0.1;
      this.viewer.view().setFov(this.currentZoomLevel);
      this.transformDisplayZoomLevel();
    }
  }

  zoomIn() {
    if (this.currentZoomLevel > this.maxZoomInLevel) {
      this.currentZoomLevel -= 0.1;
      this.viewer.view().setFov(this.currentZoomLevel);
      this.transformDisplayZoomLevel();
    }
  }
}
